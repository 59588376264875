import * as React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { MdCurrencyRupee } from 'react-icons/md';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

/** Custom Components */

class DailySummary extends React.Component {  
	constructor(props){
		super(props);
		this.state = {
			count : 0,
			sum : 0,
			selectedDate : ''
		}
	}

	render() {  
		let count = 0
		let sum = 0
		let length = 0
	
		if(this.props.data.count > 0){
			count = this.props.data.count
			sum = this.props.data.sum
			length = this.props.data.length
		}
		let user = localStorage.getItem('user');
		return (
			<>
				<Card sx={{ padding: 2,overflowX: 'auto' }}>
					<Typography gutterBottom variant="h6" component="div">Daily Summary</Typography>								
					<CardContent>
						<div className='m-2 title'>
							<Grid container spacing={2}>
								<Grid item sm={6}>Site : {this.props.data.site.site_name}</Grid>
								<Grid item sm={6} textAlign={'right'}>Date : {this.props.data.selectedDate}</Grid>
							</Grid>	
						</div>						
						<Box component="form" noValidate autoComplete="off"  sx={{display:{xs:'none',md:'block'}}}>
							<Divider textAlign="left"></Divider>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow className='table-header'>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Date</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Operator</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Total Number of Entry Pass</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Total No. of People</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Total Amount collected</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'>{this.props.data.selectedDate}</TableCell>
										<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'> {user}</TableCell>
										<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'>{length}</TableCell>
										<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'>{count}</TableCell>
										<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='right'> <MdCurrencyRupee/>{sum}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							<Divider textAlign="center">End of Report</Divider>
						</Box>
						<Box sx={{display:{xs:'block',md:'none'}}} >
							<Accordion expanded={true}>
								<AccordionSummary >
									{this.props.data.selectedDate}
								</AccordionSummary>
								<AccordionDetails>
									<Grid container spacing={2}>
										<Grid item xs={8} sx={{fontWeight:'bold'}}>Operator</Grid>
										<Grid item>{user}</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={8} sx={{fontWeight:'bold'}}>No. of Pass</Grid>
										<Grid item>{length}</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={8} sx={{fontWeight:'bold'}}>No. of People</Grid>
										<Grid item>{count}</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={8} sx={{fontWeight:'bold'}}>Amount collected</Grid>
										<Grid item><MdCurrencyRupee/>{sum}</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						</Box>
					</CardContent>
				</Card>
  			</>
		)
	}

} 
export default DailySummary