import * as React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

/** Custom Components */
import { TableFooter } from '@mui/material';
import { MdCurrencyRupee } from 'react-icons/md';

class Datewise extends React.Component {  
	constructor(props){
		super(props);
		this.state = {
			data : props.data.data
		}
	}

	render() {  

		let data = this.props.data.datewisedata;
		let length = this.props.data.length;
		let count = this.props.data.count;
		let sum = this.props.data.sum;
		let fromdate = this.props.data.sfromdate;
		let todate = this.props.data.stodate;
		return (
			<>
				<Card sx={{ padding: 2,overflowX: 'auto' }}>
					<Typography gutterBottom variant="h6" component="div">Datewise Collection</Typography>								
					<CardContent>
						<div className='m-2 title'>
							<Grid container spacing={2}>
								<Grid item sm={6}>Site : {this.props.data.site.site_name}</Grid>
								<Grid item sm={6} textAlign={'right'}>Period : {fromdate} to {todate}</Grid>
							</Grid>	
						</div>						
						<Box component="form" noValidate autoComplete="off" >
							<Divider textAlign="left"></Divider>
							<Table aria-label="simple table">
								<TableHead className='table-header'>
									<TableRow>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Sl No</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Date</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>No. of tickets</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>No. of People</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Total Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{
									data.map((item,key)=>(
										<TableRow key={key}>
											<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>{key+1}</TableCell>
											<TableCell sx={{fborder:1,borderColor: 'lightgray',padding:1,fontWeight:'500',textTransform: 'capitalize'}} align='center'>{item.entry}</TableCell>
											<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>{item.no_of_pass}</TableCell>
											<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>{item.no_of_people}</TableCell>
											<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='right'><MdCurrencyRupee/>{item.amount}</TableCell>
										</TableRow>
										))
								}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TableCell colSpan={2} sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'600',fontSize:'14px'}} align='center'>Total</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'600',fontSize:'14px'}} align='center'>{length}</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'600',fontSize:'14px'}} align='center'>{count}</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'600',fontSize:'14px'}} align='right'><MdCurrencyRupee/>{sum}</TableCell>
									</TableRow>
								</TableFooter>
							</Table>
							<Divider textAlign="center" sx={{marginTop: '10px'}}>End of Report</Divider>
						</Box>
					</CardContent>
				</Card>
  			</>
		)
	}

} 
export default Datewise