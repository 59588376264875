import React from 'react';
import { Container } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import { QrReader } from 'react-qr-reader';

import Button from '@mui/material/Button';

import { MdCheckCircleOutline,MdOutlineCancel } from "react-icons/md";

import { PAYMENT_UPDATE_URL } from '../config/config';
import NavComponent from './nav';
import { Card, Grid } from '@mui/material';
import Grow from '@mui/material/Grow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class CollectComponent extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			data : false,
			refno : '',
			message : '',
			isLoaded : false,
			ticket : '',
			open : false
		}
	}

	/**
	 * Confirm dialog handler
	 */
	handleClose = () =>{
		this.setState({open: false});
		this.handleClick();
	}
	handleClick = () =>{
		this.setState({data: false, isLoaded: false})
	}
	/**
	 * update payment status
	 */
	confirmPayment = () =>{
		fetch(PAYMENT_UPDATE_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json'
			},
			body : JSON.stringify({
				ep_ref_no : this.state.ticket,
				user: localStorage.getItem('user'),
				site: localStorage.getItem('site')
			})

		})
		.then(res => res.json())
		.then(res => {
			this.setState({data:true});
			if(res.STATUS === 0){
				this.setState({refno: this.state.ticket});
			}
			else{
				this.setState({refno:0,message:res.MESSAGE})
			}
			this.setState({open: false});
		})
	}


  	handleScan = (result) =>{
		if(this.state.isLoaded === false && result != null){
			this.setState({open:true,isLoaded: true,ticket:result.text})
		}
	}
  
	render = () =>{

		const {refno,data,message,open,ticket} = this.state;
		return (
			<>
				<NavComponent active="collect"/>
				<Container>
					<Card sx={{ padding: 2, minHeight: '80vh',overflow:'auto' }}>
						<Typography gutterBottom variant="h6" component="div" className='page-title'>
							Collect Payment
						</Typography>								
						{(data === true)?
						<>
							{(refno === 0)?
								<>
									<Grow in={data} textAlign={'right'}>
										<div>
											<Typography gutterBottom variant="h1" component="div" sx={{fontSize:'250px',color:'red',textAlign:'center'}}>
												<MdOutlineCancel/>
											</Typography>								
											<Typography gutterBottom variant="h5" component="div" sx={{color:'black',textAlign:'center',marginBottom: '10px'}}>
												{message}<br/>
											</Typography>
											<Typography sx={{textAlign:'center'}}>
												<Button  variant="contained" color="success" onClick={this.handleClick}>Back</Button>
											</Typography>
										</div>
									</Grow>
								</>
								:<>
									<Grow in={data}>
										<div>
											<Typography gutterBottom variant="h1" component="div" sx={{fontSize:'250px',color:'green',textAlign:'center'}}>
												<MdCheckCircleOutline/>
											</Typography>								
											<Typography gutterBottom variant="h5" component="div" sx={{color:'black',textAlign:'center',marginBottom: '10px'}}>
												Ticket No. {refno} payment updated
											</Typography>
											<Typography sx={{textAlign:'center'}}>
												<Button  variant="contained" color="success" onClick={this.handleClick}>Back</Button>
											</Typography>
										</div>
									</Grow>
								</>
							}
						</>
						:<>
							<Grid container spacing={2}>
								<Grid item sm={4}></Grid>
								<Grid item sm={4} xs={12}>
									<QrReader
										constraints={{facingMode:'environment'}}
										scanDelay={false}
										onResult={this.handleScan}
										style={{ width: '100%' }}
									/>
									
								</Grid>
							</Grid>
						</>
						}
					</Card>
					<Dialog open={open} onClose={this.handleClose}>
						<DialogTitle>Payment Confirmation</DialogTitle>
						<DialogContent>
						<DialogContentText>
							Are you sure to confirm payment for Ticket No. {ticket} 
						</DialogContentText>
						</DialogContent>
						<DialogActions>
						<Button onClick={this.handleClose}>Cancel</Button>
						<Button onClick={this.confirmPayment}>Ok</Button>
						</DialogActions>
					</Dialog>
				</Container>
			</>
		);
	}
};

export default CollectComponent;