import React from 'react';

class FooterComponent extends React.Component{
	render(){
		return (
			<div className="box mt-5">
				<div className="footer">
					<div id='footer-text'>
						Designed and Developed by <a target='_blank' rel="noreferrer" href='https://manipur.nic.in'><u>National Informatics Centre, Manipur </u></a><br/>
					</div>
				</div>
			</div>
		)
	}
}
export default FooterComponent