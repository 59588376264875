import React from 'react';  
import { Route,Routes,useLocation, useNavigate } from 'react-router-dom';  

/** Custom CSS */
import './App.css';  

/** Custom Components */
import './css/bootstrap.css';  
import './css/responsive.css';  
import './css/style.css'; 
import ErrorComponent from './pages/error';
import LandingComponent from './pages/landing';
import ReportComponent from './pages/report';
import EntryComponent from './pages/entry';
import PrintComponent from './pages/print';
import ReprintComponent from './pages/reprint';
import ScanComponent from './pages/scan';
import DailyReportComponent from './pages/dailyreport';
import FooterComponent from './pages/footer';
import { Grid } from '@mui/material';
import HomeComponent from './pages/home';
import DailyScanComponent from './pages/daily_scan';
import SelfEntryComponent from './pages/entryself';
import SelfPrintComponent from './pages/printself';
import CollectComponent from './pages/collect';
import PwdComponent from './pages/changepwd';
import SettingsComponent from './pages/settings';

function App() {  
	let location = useLocation();

	return (  
		<>
		<div className="hero_area">
			<header className="header_section">
			<div className="container-fluid">
				<nav className="navbar navbar-expand-lg custom_nav-container ">
				<div className="navbar-brand">
					<Grid container>
						<Grid item md={2} textAlign={'right'}>
							<img src="./images/kanglasha.png" width="50" height="50" alt="kanglasha logo"/>
						</Grid>
						<Grid item md={10}>
						<span>
							Tourist Entry Pass
						</span>
						<div className="subtitle">Government of Manipur</div>
						</Grid>
					</Grid>
				</div>
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="s-1"> </span>
					<span className="s-2"> </span>
					<span className="s-3"> </span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<div className="d-flex mx-auto flex-column flex-lg-row align-items-center"> </div>
				</div>
				</nav>
			</div>
			</header>
			<main className='root'>
				<Routes location={location}>
					<Route path="*" element={<ErrorComponent/>} exact />
					<Route path="/" element={<LandingComponent history={useNavigate()}/>} exact />
					<Route path="/logout" element={<LandingComponent history={useNavigate()}/>} exact />
					<Route path="/home" element={<HomeComponent history={useNavigate()}/>} exact />
					<Route path="/ticket" element={<ReprintComponent history={useNavigate()}/>} exact />
					<Route path="/ticketentry" element={<EntryComponent history={useNavigate()}/>} exact />
					<Route path="/ticketself" element={<SelfEntryComponent history={useNavigate()}/>} exact />
					<Route exact path="/print/:id" element={<PrintComponent history={useNavigate()} exact />}/>
					<Route exact path="/printticket/:id" element={<SelfPrintComponent history={useNavigate()} exact />}/>
					<Route path="/report" element={<ReportComponent/>} exact />
					<Route path="/daily" element={<DailyReportComponent/>} exact />
					<Route path="/dailyscan" element={<DailyScanComponent/>} exact />
					<Route path="/scan" element={<ScanComponent/>} exact />
					<Route path="/collect" element={<CollectComponent/>} exact />
					<Route path="/pwd" element={<PwdComponent history={useNavigate()}/>} exact />
					<Route path="/settings" element={<SettingsComponent history={useNavigate()}/>} exact />
					<Route path="/error" element={<ErrorComponent/>} />
				</Routes>
			</main>

		</div>
		<FooterComponent/>
	  </>
	);  
}  
  
export default App; 