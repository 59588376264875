import React from 'react';
import { Link } from 'react-router-dom';  

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Button, Drawer, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { LOGIN_URL, SITES_URL } from '../config/config';
import { MdCancel } from 'react-icons/md';
import { Container } from 'react-bootstrap';
import { sha256 } from 'js-sha256';

/** Custom Components */


class LandingComponent extends React.Component {  
	constructor(props){
		super(props);
		this.state = {
			open : false,
			sites : [],
			site_code : '',
			username : '',
			password : ''
		}
	}

	componentDidMount = () =>{

		fetch(SITES_URL,{
			method:'POST',
			headers: {
				'Content-type' : 'application/json'
			}
		})
		.then(res => res.json())
		.then(res => {
			this.setState({
				sites : res.DATA
			});
		})
		.catch(error => {
			//alert('Error in loading data !');
		})

	}

	closeDrawer = () =>{
		this.setState({
			open: false,
			username: '',
			password:''
		})
	}

	onChangeSite = (event) =>{
		localStorage.setItem('site',event.target.value)
		this.setState({site: event.target.value})
	}
	onChangeUsername = (event) =>{
		this.setState({username: event.target.value})
	}
	onChangePassword = (event) =>{
		this.setState({password: event.target.value})
	}

	login = () =>{
		const {username,password,site} = this.state;

		if(username === ""||password === ""||site === ""){
			alert("Provide valid login details!");
			return false;
		}

		let s = this.generate();
		let pw = password;//s+(sha256(sha256(password)+s));
		var formData = new FormData();
		formData.append('un',username);
		formData.append('pd',pw);
		formData.append('site',site);

		fetch(LOGIN_URL,{
			method:'POST',
			body: formData
		})
		.then(res => res.json())
		.then(res => {
			if(res.STATUS === 0){
				localStorage.setItem('ACCESS_TOKEN',res.TOKEN);
				if(username.trim() === password.trim())
					localStorage.setItem('FORCE_UPDATE',1);
				else
					localStorage.setItem('FORCE_UPDATE',0);
				this.props.history(`/home`);
				return false;
			}
			else{
				alert(res.MESSAGE);
				this.setState({password:''})
				//window.location.href = "/";
			}
		})
		.catch(error => {
			alert('Error in login!');
			this.setState({password:''})
			return false;
		})
	}

	generate = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const cl = characters.length;
        for(let i = 0; i < 5; i++) {
            result +=  characters.charAt(Math.floor(Math.random() * cl));
        }
        return result;
    }


	render() { 
		const { sites }  = this.state;
		return (
			<>
				<Grid container>
					<Grid item xs={1} md={9} sm={7}></Grid>
					<Grid item md={1} xs={5} sm={2} sx={{textAlign:'right',marginRight:'5px'}}>
						<Link to="/ticketself" className="loginbtn">Book Ticket</Link>
					</Grid>
					<Grid item md={1} xs={5} sm={2} sx={{textAlign:'right',marginRight:'5px'}}>
						<Link onClick={()=>{this.setState({open: true})}} className="loginbtn">Official Login</Link>
					</Grid>
				</Grid>
				<section className="slider_section">
					<div id="carouselIndicators" className="carousel slide" data-ride="carousel" data-interval="3000" >
						{(sites.length > 0)?
						<>
							<div className="carousel-inner">
							{ 
								sites.map((row,key)=>(
								<div className={(row.site_code==='MPC')?"carousel-item active":"carousel-item"} key={key}>
									<Container>
										<Grid container sx={{height:{xs:'370px',sm:'390px',md:'480px'}}}>
											<Grid item md={7} xs={12}>
												<div className="detail_box">
													<h1>{row.site_name}</h1>
													<p>{row.description}</p>
													<p>{row.address}</p>
													<p>Summer : {row.summer} (March-October)</p>
													<p>Winter : {row.winter} (November-February)</p>
												</div>
										</Grid>
										<Grid item md={4} xs={12} sx={{display:{xs:'none',sm:'none',md:'block'}}}>
											<div className="img-box">
												<img src={"images/"+row.site_code+".jpg"} alt={row.site_name+ "Site Image"} />
											</div>
										</Grid>
									</Grid>
									</Container>
								</div>
								))
							}
							</div>
							<div className="carousel_btn-container">
								<a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
									<span className="sr-only">Previous</span>
								</a>
								<a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
									<span className="sr-only">Next</span>
								</a>
							</div>
						</>:<>
							<div className="carousel-inner">
							<div className="carousel-item active">
									<Container>
										<Grid container>
											<Grid item sm={7} xs={12}>
												<div className="detail_box">
													<h3>Loading data...</h3>
													<p>It may take several minutes while loading !!</p>
												</div>
												<div className='btn-box'></div>
										</Grid>
									</Grid>
									</Container>
								</div>
							</div>
						</>}
					</div>
				</section>
				<Drawer anchor={'right'} open={this.state.open} 
					ModalProps={{ onBackdropClick: this.closeDrawer }} PaperProps={{ style: { width: '330px' }}}>
					<div className='loginForm'>
						<Box component="form" noValidate autoComplete="off" sx={{marginTop: '30px'}}>
							<Grid container spacing={2}>
								<Grid item xs={6} >
									<h3>Login</h3>
								</Grid>
								<Grid item xs={6} sx={{textAlign:'right',fontSize:'20px'}}>
									<Button variant='outlined' onClick={this.closeDrawer}> <MdCancel/> </Button>
								</Grid>
							</Grid>
							<Grid container spacing={2} sx={{padding:'10px'}}>
								<Grid item xs={12}>
									<FormControl fullWidth>
										<InputLabel id="site_code">Site</InputLabel>
										<Select label="site" onChange={this.onChangeSite} defaultValue=''>
											{ sites.map(item=>
												<MenuItem value={item.site_code} key={item.site_code}>{item.site_name}</MenuItem>
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={2} sx={{padding:'10px'}}>
								<Grid item xs={12}>
									<TextField fullWidth label="Username" value={this.state.username} variant="outlined" onChange={this.onChangeUsername}/>
								</Grid>
							</Grid>
							<Grid container spacing={2} sx={{padding:'10px'}}>
								<Grid item xs={12}>
									<TextField fullWidth label="Password" value={this.state.password} variant="outlined" type="password" onChange={this.onChangePassword}/>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12} textAlign={'right'}>
									<Link className="loginbtn" onClick={this.login}>Login</Link>
								</Grid>
							</Grid>
						</Box>
					</div>
				</Drawer>
  			</>
		)
	}

} 
export default LandingComponent