import * as React from 'react';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

/** Custom Components */
import { Container } from 'react-bootstrap'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NavComponent from './nav';
import CommitteeComponent from './committee';
import RegisterComponent from './register';
import UserComponent from './users';


class SettingsComponent extends React.Component {  

	constructor(props){
		super(props);
		this.state = {
			activemenu: 0
		}
	}

	componentDidMount = () => {
		//this.getSiteDetail();
		//this.getDatewiseSummary();
	}

	handleChange = (event) =>{
		this.setState({activemenu: parseInt(event.target.id)});
	}

	render() {  
		const { activemenu } = this.state;
		return (
			<>
				<NavComponent active="settings"/>
				<Container>
					<Card sx={{ minWidth: 275, padding: 2, minHeight: '80vh',overflow:'auto'  }}>
						<Typography gutterBottom variant="h6" component="div" className='page-title'>Settings</Typography>								
						<Grid container>
							<Grid item md={3} xs={12} textAlign={'right'}>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={activemenu}
									onChange={this.handleChange}
									aria-label="Vertical tabs example"
									sx={{ borderRight: 1, borderColor: 'divider' }}
								>
									<Tab label="Add Committee" id={0} />
									<Tab label="Register User" id={1} />
									<Tab label="Manage User" id={2} />
								</Tabs>
							</Grid>
							<Grid item md={9}>
							{
								(activemenu === 0)?
								<>
									<CommitteeComponent/>
								</>:<></>

							}
							{
								(activemenu === 1)?
								<>
									<RegisterComponent/>
								</>:<></>

							}
							{
								(activemenu === 2)?
								<>
									<UserComponent/>
								</>:<></>

							}
							</Grid>
						</Grid>
					</Card>
				</Container>
  			</>
		)
	}

} 
export default SettingsComponent