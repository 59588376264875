import * as React from 'react';
import { Link } from 'react-router-dom';  
import jwt_decode from "jwt-decode";

class NavComponent extends React.Component { 
	constructor(props){
		super(props);
		this.state = {
			active: props.active,
			role : ''
		};
	}
	
	componentDidMount = () =>{
		var token = localStorage.getItem('ACCESS_TOKEN');
		if(token == null){
			alert("Invalid Session!");
			//this.props.history(`/`)
			window.location.href = "/";
			return false;
		}
		var decoded = jwt_decode(token);
		this.setState({role: decoded.role})
	}

	checkValid = () =>{
		var token = localStorage.getItem('ACCESS_TOKEN');
		var decoded = jwt_decode(token);
		 
		let current = Math.round((new Date()).getTime()/1000);
		if(decoded.exp < current){
			/*alert("Your session has expired. Please Login Again.");
			localStorage.clear();
			window.location.href = "/";
			return false;*/
		}

	}

	render() {
		const {active,role} = this.state;  
		return (
			<>
				<nav className="navbar navbar-expand-lg custom_nav-container ">
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<div className="d-flex mx-auto flex-column flex-lg-row align-items-center">
						<ul className="navbar-nav">
							{
								(role === "operator")?(
									<>
										<li className={(active === 'entry')?'nav-item active':'nav-item'}>
											<Link to="/ticketentry" className='nav-link' onClick={this.checkValid}>Entry </Link>
										</li>
										<li className={(active === 'reprint')?'nav-item active':'nav-item'}>
											<Link to="/ticket" className='nav-link' onClick={this.checkValid}>Reprint Ticket </Link>
										</li>
										<li className={(active === 'daily')?'nav-item active':'nav-item'}>
											<Link to="/daily" className='nav-link' onClick={this.checkValid}>Daily Collection</Link>
										</li>
										<li className={(active === 'pwd')?'nav-item active':'nav-item'}>
											<Link to="/pwd" className='nav-link' onClick={this.checkValid}>Change Password </Link>
										</li>
										<li className="nav-item">
										<Link to="/logout" name="logout" className='nav-link' id="logout" onClick={()=>{localStorage.clear();}}>Logout </Link>
										</li>
									</>
								):<></>
							}
							{
								(role === "officer")?(
									<>
										<li className={(active === 'reprint')?'nav-item active':'nav-item'}>
											<Link to="/ticket" className='nav-link' onClick={this.checkValid}>Reprint Ticket </Link>
										</li>
										<li className={(active === 'report')?'nav-item active':'nav-item'}>
											<Link to="/report" className='nav-link' onClick={this.checkValid}>Report</Link>
										</li>
										<li className={(active === 'settings')?'nav-item active':'nav-item'}>
											<Link to="/settings" className='nav-link' onClick={this.checkValid}>Settings </Link>
										</li>
										<li className={(active === 'pwd')?'nav-item active':'nav-item'}>
											<Link to="/pwd" className='nav-link' onClick={this.checkValid}>Change Password </Link>
										</li>
										<li className="nav-item">
										<Link to="/logout" name="logout" className='nav-link' id="logout" onClick={()=>{localStorage.clear();}}>Logout </Link>
										</li>
									</>
								):<></>
							}
							{
								(role === "gatekeeper")?(
									<>
										<li className={(active === 'reprint')?'nav-item active':'nav-item'}>
											<Link to="/ticket" className='nav-link' onClick={this.checkValid}>Reprint Ticket </Link>
										</li>
										<li className={(active === 'scan')?'nav-item active':'nav-item'}>
											<Link to="/scan" className='nav-link' onClick={this.checkValid}>Scan </Link>
										</li>
										<li className={(active === 'dailyscan')?'nav-item active':'nav-item'}>
											<Link to="/dailyscan" className='nav-link' onClick={this.checkValid}>Daily Scan </Link>
										</li>
										<li className={(active === 'pwd')?'nav-item active':'nav-item'}>
											<Link to="/pwd" className='nav-link' onClick={this.checkValid}>Change Password </Link>
										</li>
										<li className="nav-item">
										<Link to="/logout" name="logout" className='nav-link' id="logout" onClick={()=>{localStorage.clear();}}>Logout </Link>
										</li>
									</>
								):<></>
							}
							{
								(role === "collection")?(
									<>
										<li className={(active === 'reprint')?'nav-item active':'nav-item'}>
											<Link to="/ticket" className='nav-link' onClick={this.checkValid}>Reprint Ticket </Link>
										</li>
										<li className={(active === 'collect')?'nav-item active':'nav-item'}>
											<Link to="/collect" className='nav-link' onClick={this.checkValid}>Collect Payment </Link>
										</li>
										<li className={(active === 'pwd')?'nav-item active':'nav-item'}>
											<Link to="/pwd" className='nav-link' onClick={this.checkValid}>Change Password </Link>
										</li>
										<li className="nav-item">
											<Link to="/logout" name="logout" className='nav-link' id="logout" onClick={()=>{localStorage.clear();}}>Logout </Link>
										</li>
									</>
								):<></>
							}
							</ul>
						</div>
					</div>
				</nav>
  			</>
		)
	}

} 
export default NavComponent