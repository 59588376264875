import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

/** Custom Components */
import { ADD_COMM_URL, SITES_URL } from '../config/config';
import { Grid } from '@mui/material';

class CommitteeComponent extends React.Component {  

	constructor(props){
		super(props);
		this.state = {
			site : localStorage.getItem("site"),
			sites : [],
			name : '',
			description: ''
		}
	}

	componentDidMount = () =>{
		fetch(SITES_URL,{
			method:'POST',
			headers: {
				'Content-type' : 'application/json'
			}
		})
		.then(res => res.json())
		.then(res => {
			this.setState({
					sites : res.DATA
				});
		})
		.catch(error => {
			//alert('Error in loading data !');
		})
		
	}

	onChangeName = (event) =>{
		this.setState({name : event.target.value});
	}
	onChangeDescription = (event) =>{
		this.setState({description : event.target.value});
	}

	submitForm = () =>{
		if(this.state.name.length === 0){
			alert("Committee Name is required !");
			return false;
		}
		fetch(ADD_COMM_URL,{
				method:'POST',
				headers: {
					'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
					'Content-type' : 'application/json'
				},
				body : JSON.stringify({
					name: this.state.name,
					user : localStorage.getItem('user'),
					description : this.state.description,
					site : localStorage.getItem('site')
				})
			})
			.then(res => res.json())
			.then(res => {
				if(res.STATUS === 0){
					alert("Added successfully!");
					this.setState({name: '',description: ''})
				}
				else
					alert(res.MESSAGE);
				})
			.catch(error => {
				alert('Error:: Unable to save data!!');
			})
	}


	render() {  
		const { sites,site } = this.state;
		return (
			<>
				<Card>
					<Typography gutterBottom variant="h6" component="div" textAlign={'center'}>Add Committee</Typography>								
					<Grid container spacing={2}>
						<Grid item md={12} xs={12}>
							<Grid container spacing={2} className="m-1">
								<Grid item md={2}></Grid>
								<Grid item xs={10} md={6}>
									<FormControl fullWidth>
										<InputLabel id="site_code">Site</InputLabel>
										<Select label="site" onChange={this.onChangeSite} defaultValue={site} disabled>
											{ sites.map(item=>
												<MenuItem value={item.site_code} key={item.site_code}>{item.site_name}</MenuItem>
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={2} className="m-1">
								<Grid item md={2}></Grid>
								<Grid item xs={10} md={6}>
									<TextField fullWidth label="Committee Name" value={this.state.name} inputProps={{ maxLength: 50 }} onChange={this.onChangeName}/>
								</Grid>
							</Grid>
							<Grid container spacing={2} className="m-1">
								<Grid item md={2}></Grid>
								<Grid item xs={10} md={6}>
									<TextField fullWidth label="Description" value={this.state.description} inputProps={{ maxLength: 50 }} onChange={this.onChangeDescription}/>
								</Grid>
							</Grid>
							<Grid container spacing={2} className="m-1">
								<Grid item md={8} textAlign={'right'}>
								<Button  variant="contained" color="success" sx={{margin:'10px'}} onClick={this.submitForm}>Save</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Card>
  			</>
		)
	}

} 
export default CommitteeComponent