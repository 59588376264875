import { Br, Printer, Text, Row } from "react-thermal-printer";

const TicketHeader = (props) => {
	return(
		<Printer type="epson" width={30} >
			<Text bold={true} align="center" size={{width:1,height:1}} font="arial">{`${props?.site_name}`}</Text>
			<Text align="center">{`${props?.address}`}</Text>
			<Text bold={true} align="center" underline={true} >Entry Ticket</Text>
			<Row left={`Date ${props?.entry}${props?.reprint}`} right={`No. ${props?.ep_ref_no}`} />
			<Br/>
		</Printer>
	);
}
export default TicketHeader;