import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from 'react-router-dom';  
import { Container,Row,Col } from 'react-bootstrap';  


function ErrorComponent(){
	return (
		<>
			<main>
				<Container className="centered">
					<Row>
						<Col className="col-1"></Col>
						<Col>404 error<FaExclamationTriangle/> <h6>The requested URL doesn't exist on the server.<br/>
						Click <Link to="/">here</Link> to go back to application.</h6></Col>
					</Row>
				</Container>
			</main>
		</>
	)
}
export default ErrorComponent