import * as React from 'react';
import {  useRef } from "react";
import { Link,useParams } from 'react-router-dom';  
import { Button, Container } from "@mui/material";
import ReactToPrint from 'react-to-print';
import jsPDF from "jspdf";

/** Custom Components */
import TicketComponent from './ticket';
import { Grid } from '@mui/material';

const SelfPrintComponent = ()=> {  
	let { id } = useParams();

	const inputElement = useRef();
	const componentRef = useRef();
	const backButton = useRef();

	
	return (
		<>
			<Container>
				<Grid container spacing={2}>
					<Grid item sm={4}></Grid>
					<Grid item sm={4} xs={12}>
						<TicketComponent data={id} ref={componentRef}/>
						<div style={{ display: "",textAlign:"center" }}>
							<Link to="/ticketself">
								<Button  ref={backButton} variant="contained" color="success" sx={{display:'none'}}>Back</Button>
							</Link>
							<ReactToPrint
								trigger={() => {
									return <Button  ref={inputElement} variant="contained" color="success" sx={{margin:'10px'}}>Download</Button>;
								}}					
								content={() => componentRef.current}
								onAfterPrint={()=>{
									//backButton.current.click();
								}}
								print={ async (printIframe) => {
									const document = printIframe.contentDocument;
									if (document) {
									  	const html = document.getElementById("ticket");
										const doc = new jsPDF("p", "pt", "dl");
									  	doc.html(html, {
											callback: function (doc) {
												doc.save('BookTicket'+id+'.pdf');
											},

										})
									}
								  }
								}
								removeAfterPrint = {false}
								/>
							<Link to="/ticketself">
								<Button  variant="contained" color="success" sx={{margin:'10px'}}>Back</Button>
							</Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</>
	)
}
export default SelfPrintComponent
