import { Cut, Line, Printer, Text, Row, QRCode } from "react-thermal-printer";

const TicketFooter = (props) => {
	return(
		<Printer type="epson" width={30}>
			<Line/>
			<Row left={`Total              ${props?.count}`} right={`Rs.${props?.amount}`} />
			<QRCode align="center" cellSize={7} content={`${props?.ep_ref_no}`} />
			<Line/>
			<Text align="center">Keep ticket till exit time</Text>
			<Text align="center">{`Timing : ${props?.time}`}</Text>
			<Text align="center">Thanks for your visit</Text>
			<Text align="center">Issued by {`${props.entered_by}`}</Text>
			<Cut lineFeeds={3}/>
		</Printer>
	);
}
export default TicketFooter;

