import * as React from 'react';
import {  useRef } from "react";
import { Link,useParams } from 'react-router-dom';  
import { render } from "react-thermal-printer";
import { Button, Container } from "@mui/material";
import { RATES_URL, SITE_DETAIL_URL, TICKET_DATA_URL } from '../config/config';
import { useEffect, useState } from "react";
import TicketHeader from "./ticketheader";
import TicketData from "./ticketdata";
import TicketFooter from "./ticketfooter";
import ReactToPrint from 'react-to-print';
import jsPDF from "jspdf";

/** Custom Components */
import TicketComponent from './ticket';
import NavComponent from './nav';
import { Grid } from '@mui/material';

const PrintComponent = ()=> {  
	let { id } = useParams();

	const componentRef = useRef();
	const backButton = useRef();
	const inputElement = useRef();

	const [ticket, setTicket] = useState(null);
	const [rate, setRates] = useState(null);
	const [site, setSite] = useState(null);
	useEffect(()=>{
		if(ticket == null)
			getTicket();
	})
	const getTicket = async() =>{
		const res = await fetch(TICKET_DATA_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json'
			},
			body : JSON.stringify({ep_ref_no : id,site: localStorage.getItem('site')})
		});
		res.json()
		  .then(res => {
			setTicket(res.DATA);
			getSiteDetail(res.DATA.site_code);
			getRates(res.DATA.site_code);
		  })
	}

	/** Site Details */
	const getSiteDetail = (site) =>{
		fetch(SITE_DETAIL_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json'
			},
			body : JSON.stringify({site: site})

		})
		.then(res => res.json())
		.then(res => {
			if(res.STATUS === 0){
				setSite(res.DATA)
				return false;
			}
			else{
				alert("Error getting site information");
			}
		})
	}

	/** Get Rates */
	const getRates = (site) =>{
		fetch(RATES_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json'
			},
			body: JSON.stringify({site: site})

		})
		.then(res => res.json())
		.then(res => {
			setRates(res.DATA)
			return false;
		})
	}
	var connectedDevice = null;
    const onClickPrintHandler = async () => {
		let obj = {
			...ticket,
			...rate,
			...site
		}
		const header = await render(TicketHeader(obj));
		const data = await render(TicketData(obj));
		const footer = await render(TicketFooter(obj));
		if(connectedDevice != null){
			//console.log(connectedDevice.gatt.connect())
			//return;
		}

	  var SERVICE = '000018f0-0000-1000-8000-00805f9b34fb';
	  var WRITE = '00002af1-0000-1000-8000-00805f9b34fb';
	  
	  var deviceHandle;
		window.navigator.bluetooth.requestDevice(
			{filters:[{name:'MPT-II'}],
			optionalServices:['000018f0-0000-1000-8000-00805f9b34fb']}
		).then(device => {
			//console.log(device);
			deviceHandle = device;
			connectedDevice = device;
			return device.gatt.connect()
		}).then(server => {
			//console.log(server);
			return server.getPrimaryService(SERVICE);
		}).then(service => {
			//console.log(service);
			return service.getCharacteristic(WRITE);
		}).then(channel => {
			//console.log(channel);
			channel.writeValue(header)
			.then(() => {
				channel.writeValue(data)
				.then(() => {
					channel.writeValue(footer)
					deviceHandle.gatt.disconnect();
				})
			})
		}).catch(error => {
			alert(error)
		}).finally(() => {
		});
  }

	return (
		<>
			<NavComponent active="entry"/>
			<Container>
				<Grid container spacing={2}>
					<Grid item sm={4}></Grid>
					<Grid item sm={6} xs={12}>
						<TicketComponent data={id} ref={componentRef} />
						<div style={{ display: "",textAlign:"left" }}>
							<Link to="/ticketentry">
								<Button  ref={backButton} variant="contained" color="success" sx={{display:'none'}}>Back</Button>
							</Link>
							<Button  variant="contained" color="success" sx={{margin:'10px'}} onClick={onClickPrintHandler}>Print</Button>
							<ReactToPrint
								trigger={() => {
									return <Button  ref={inputElement} variant="contained" color="success" sx={{margin:'10px'}}>Download</Button>;
								}}					
								content={() => componentRef.current}
								onAfterPrint={()=>{
									//backButton.current.click();
								}}
								print={ async (printIframe) => {
									const document = printIframe.contentDocument;
									if (document) {
									  	const html = document.getElementById("ticket");
										const doc = new jsPDF("p", "pt", "dl",true);
									  	doc.html(html, {
											callback: function (doc) {
												doc.save('BookTicket'+id+'.pdf');
											},

										})
									}
								  }
								}
								removeAfterPrint = {false}
								/>
							<Link to="/ticketentry">
								<Button  variant="contained" color="success" sx={{margin:'10px'}}>Back</Button>
							</Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</>
	)
}
export default PrintComponent
