import * as React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { MdExpandMore } from 'react-icons/md';

/** Custom Components */
import { TableFooter } from '@mui/material';

class ScanReport extends React.Component {  
	constructor(props){
		super(props);
		this.state = {
			data : props.data.data,
			expanded : 0
		}
	}
	
	handleChange = (panel) =>{
		if(this.state.expanded === panel.target.innerHTML){
			this.setState({expanded : ''});
		}
		else
			this.setState({expanded : panel.target.innerHTML});
	}

	render() {  
		const { expanded } = this.state;
		let data = this.props.data.data;
		let length = this.props.data.length;
		let count = this.props.data.count;
		let selectedDate = this.props.data.selectedDate;
		let fdate = this.props.data.sfromdate;
		let tdate = this.props.data.stodate;
		let date = '';
		if(selectedDate === '')
			date = selectedDate;
		else
			date = fdate+' to '+tdate;

			return (
			<>
				<Card sx={{padding: 1,overflowX: 'auto' }}>
					<Typography gutterBottom variant="h6" component="div">Datewise Scan Report</Typography>								
					<div className='m-2 title'>
						<Grid container spacing={2}>
							<Grid item sm={6}>Site : {this.props.data.site.site_name}</Grid>
							<Grid item sm={6} textAlign={'right'}>Date : {date} </Grid>
						</Grid>	
					</div>						
					<Box  sx={{display:{xs:'none',md:'block'}}} >
						<Divider textAlign="left"></Divider>
						<Table aria-label="simple table">
							<TableHead className='table-header'>
								<TableRow>
									<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Sl No</TableCell>
									<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Scan Date</TableCell>
									<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>No. of tickets</TableCell>
									<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>No. of People</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
							{
								data.map((item,key)=>(
									<TableRow key={key}>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>{key+1}</TableCell>
										<TableCell sx={{fborder:1,borderColor: 'lightgray',padding:1,fontWeight:'500',textTransform: 'capitalize'}} align='center'>{item.entry}</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>{item.no_of_pass}</TableCell>
										<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>{item.no_of_people}</TableCell>
									</TableRow>
									))
							}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TableCell colSpan={2} sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'600',fontSize:'14px'}} align='center'>Total</TableCell>
									<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'600',fontSize:'14px'}} align='center'>{length}</TableCell>
									<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'600',fontSize:'14px'}} align='center'>{count}</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
						<Divider textAlign="center" sx={{marginTop: '10px'}}>End of Report</Divider>
					</Box>
					<Box sx={{display:{xs:'block',md:'none'},marginBottom:'20px'}} >
						<div className='m-2'>
							<Grid container spacing={2}>
								<Grid item xs={6}>No. of records</Grid>
								<Grid item xs={6} textAlign={'right'}>{length}</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={6}>No. of people</Grid>
								<Grid item xs={6} textAlign={'right'}>{count}</Grid>
							</Grid>
						</div>	

						{
							data.map((item,key)=>(
								<Accordion expanded={String(expanded).includes(item.entry)} onChange={this.handleChange} key={key}>
									<AccordionSummary expandIcon={<MdExpandMore />}  >
										{key+1}. {item.entry}
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											<Grid item xs={8} sx={{fontWeight:'bold'}}>No. of tickets</Grid>
											<Grid item xs={4} textAlign={'right'}>{item.no_of_pass}</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={8} sx={{fontWeight:'bold'}}>No. of people</Grid>
											<Grid item xs={4} textAlign={'right'}>{item.no_of_people}</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							))
						}
					</Box>
				</Card>
  			</>
		)
	}

} 
export default ScanReport