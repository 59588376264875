import { Line, Printer, Row } from "react-thermal-printer";

const TicketData = (props) => {

	return(
		<Printer type="epson" width={30}>
			<Row left={`Type             No.`} right="Amt(Rs)" />
			<Line/>
			<Row left={`Adult              ${props?.no_of_adults}`} right={`${props?.no_of_adults * props.DA}`} />
			<Row left={`Child              ${props?.no_of_children}`} right={`${props?.no_of_children* props.DC}`} />
			<Row left={`Adult(Foreigner)   ${props?.no_of_foreign_adults}`} right={`${props?.no_of_foreign_adults* props.FA}`} />
			<Row left={`Child(Foreigner)   ${props?.no_of_foreign_children}`} right={`${props?.no_of_foreign_children* props.FC}`} />
		</Printer>
	);
}
export default TicketData;