import * as React from 'react';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ReactToPrint from 'react-to-print';
import "react-datepicker/dist/react-datepicker.css";

/** Custom Components */
import { Container } from 'react-bootstrap'; 
import Grid from '@mui/material/Grid';
import { MdPrint } from 'react-icons/md';

import { SCANSUMMARY_URL, SITE_DETAIL_URL } from '../config/config';
import NavComponent from './nav';
import { TextField } from '@mui/material';
import ScanUserwise from './scanuserwise';
import ScanReport from './scanreport';

class DailyScanComponent extends React.Component {  

	constructor(props){
		super(props);
		let today = new Date().getFullYear()+'-'+String(new Date().getMonth()+1).padStart(2,'0')+'-'+String(new Date().getDate()).padStart(2,'0');
		this.state = {
			site: {},
			date : today,
			count : 0,
			sum : 0,
			data : [],
			userwise : [],
			length : 0
		}
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount = () =>{
		this.handleClick();
		this.getSiteDetail();
	}

	onChangeDate = (event) =>{
		this.setState({date: event.target.value});
	}
	formatDMY = (ymd) =>{
		return (ymd.substr(8,2)+'-'+ymd.substr(5,2)+'-'+ymd.substr(0,4));
	}

	/** Site Details */
	getSiteDetail = () =>{
		fetch(SITE_DETAIL_URL,{
			method:'POST',
			headers: {
				'Content-type' : 'application/json',
				'Authorization' : localStorage.getItem('ACCESS_TOKEN')
			},
			body : JSON.stringify({site: localStorage.getItem('site')})

		})
		.then(res => res.json())
		.then(res => {
			if(res.STATUS === 0){
				this.setState({site: res.DATA})
				return false;
			}
			else{
				alert("Error getting site information");
			}
		})
		.catch(error => {
			alert('Error !');
			return false;
		})

	}

	handleClick = () =>{
		const {date} = this.state;
		let dmy = this.formatDMY(date);
		this.setState({selectedDate: dmy});
		
		fetch(SCANSUMMARY_URL,{
			method:'POST',
			headers: {
				'Content-type' : 'application/json',
				'Authorization' : localStorage.getItem('ACCESS_TOKEN')
			},
			body : JSON.stringify({
				site : localStorage.getItem('site'),
				user : localStorage.getItem('user'),
				fromdate : date,todate : date
			})

		})
		.then(res => res.json())
		.then(res => {
			this.setState({userwise: res.USERWISE, data: res.DATA,count: res.COUNT,sum: res.TOTAL_SUM,length: res.LENGTH});
		})
		.catch(error => {
			alert('Error in loading report !');
		})
	}

	render() {  
		const {date} = this.state;
		return (
			<>
				<NavComponent active="dailyscan"/>
				<Container>
					<Card sx={{ padding: 2,overflowX: 'auto' }}>
						<Typography gutterBottom variant="h6" component="div" className='page-title'>Daily Report</Typography>
						<Grid container spacing={2}>
							<Grid item sm={1} xs={2}>Date</Grid>
							<Grid item sm={3} xs={6}>
								<TextField type="date" size='small' value={date} variant='outlined' fullWidth onChange={this.onChangeDate}/>
							</Grid>
							<Grid item sm={1} xs={3}>
								<Button variant="contained" color="success" onClick={this.handleClick}>Load</Button>
							</Grid>
							<Grid item xs={2}></Grid>
							<Grid item sm={1} xs={4}>
								<ReactToPrint
									trigger={() => {
										return <Button variant="contained" color="success">Detail <div className="printicon"><MdPrint/></div></Button>;
									}}
									content={() => this.componentRef}
								/>
							</Grid>
							<Grid item sm={2} xs={6} className='text-center'>
								<ReactToPrint
									trigger={() => {
										return <Button variant="contained" color="success">Summary<MdPrint/></Button>;
									}}
									content={() => this.summaryRef}
								/>
							</Grid>
						</Grid>							
						<ScanUserwise data={this.state} ref={el => (this.summaryRef = el)} />
						<ScanReport data={this.state} ref={el => (this.componentRef = el)} />
					</Card>
				</Container>
  			</>
		)
	}

} 
export default DailyScanComponent