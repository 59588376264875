import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { MdCurrencyRupee, MdExpandMore } from 'react-icons/md';

/** Custom Components */
import { Box, TableFooter } from '@mui/material';

class DailyCollection extends React.Component {  
	constructor(props){
		super(props);
		this.state = {
			tickets : [],
			count : 0,
			sum : 0,
			selectedDate : '',
			dateStr : props.selectedDate,
			expanded : 0
		}
	}

	handleChange = (panel) =>{
		if(this.state.expanded === panel.target.innerHTML){
			this.setState({expanded : ''});
		}
		else
			this.setState({expanded : panel.target.innerHTML});
	}
	render() {
		const {expanded} = this.state;  
		let tickets = []
		let count = 0
		let sum = 0
	
		if(this.props.data.count > 0){
			tickets = this.props.data.tickets
			count = this.props.data.count
			sum = this.props.data.sum
		}
		return (
			<>
				<Card sx={{ minHeight: '80vh', padding: 2,overflowX: 'auto' }}>
					<Typography gutterBottom variant="h6" component="div">Collection Details</Typography>								
					<CardContent>
						<div className='m-2 title'>
							<Grid container spacing={2}>
								<Grid item sm={6}>Site : {this.props.data.site.site_name}</Grid>
								<Grid item sm={6} textAlign={'right'}>Date : {this.props.data.selectedDate}</Grid>
							</Grid>	
						</div>						
						<Grid container >
							<Grid item md={12}>
								<Box sx={{display:{xs:'none',md:'block'}}} >
									<Divider textAlign="left"></Divider>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow className='table-header'>
												<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Sl No</TableCell>
												<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Ticket No</TableCell>
												<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Mobile</TableCell>
												<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Entered By</TableCell>
												<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Count</TableCell>
												<TableCell sx={{ border:1,borderColor: 'lightgray',padding:1,fontWeight:'500'}} align='center'>Amount</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
										{
											tickets.map((item,key)=>(
												<TableRow key={key}>
													<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'>{key+1}</TableCell>
													<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'>{item.ep_ref_no}</TableCell>
													<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'>{item.mobile_no}</TableCell>
													<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'> {item.entered_by}</TableCell>
													<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'>{item.count}</TableCell>
													<TableCell sx={{padding:'5px',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='right'> <MdCurrencyRupee/> {item.amount}</TableCell>
												</TableRow>
												))
										}
										</TableBody>
										<TableFooter>
											<TableRow>
												<TableCell colSpan={4} sx={{textAlign: 'center',fontWeight:'bold',fontSize:'13px', border:1,borderColor: 'lightgray'}}>Total</TableCell>
												<TableCell sx={{fontWeight:'bold',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='center'>{count}</TableCell>
												<TableCell sx={{padding:'5px',fontWeight:'bold',fontSize:'13px', border:1,borderColor: 'lightgray'}} align='right'><MdCurrencyRupee/> {sum}</TableCell>
											</TableRow>
										</TableFooter>
									</Table>
									<Divider textAlign="center" sx={{marginTop:'10px'}}>End of Report</Divider>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box sx={{display:{xs:'block',md:'none'}}} >
								{
									tickets.map((item,key)=>(
										<Accordion expanded={String(expanded).includes(item.ep_ref_no)} onChange={this.handleChange} key={item.ep_ref_no}>
											<AccordionSummary expandIcon={<MdExpandMore />}  >
												{key+1}. {item.ep_ref_no}
											</AccordionSummary>
											<AccordionDetails>
												<Grid container spacing={2}>
													<Grid item xs={6} sx={{fontWeight:'bold'}}>Sl No</Grid>
													<Grid item>{key+1}</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={6} sx={{fontWeight:'bold'}}>Mobile No</Grid>
													<Grid item>{item.mobile_no}</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={6} sx={{fontWeight:'bold'}}>Operator</Grid>
													<Grid item xs={6}>{item.entered_by}</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={6} sx={{fontWeight:'bold'}}>Count</Grid>
													<Grid item>{item.count}</Grid>
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={6} sx={{fontWeight:'bold'}}>Amount</Grid>
													<Grid item><MdCurrencyRupee/> {item.amount}</Grid>
												</Grid>
											</AccordionDetails>
									</Accordion>
								
								))}
								</Box>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</>
		)
	}

} 
export default DailyCollection