import React from 'react';
import jwt_decode from "jwt-decode";

class HomeComponent extends React.Component{


	componentDidMount = () => {
		var token = localStorage.getItem('ACCESS_TOKEN');
		if(token == null){
			window.location.href = "/";
			return false;
		}
		var decoded = jwt_decode(token);
		var role = decoded.role;
		localStorage.setItem('user', decoded.username);
		localStorage.setItem('site', decoded.site);


		if(localStorage.getItem("FORCE_UPDATE") == 1){
			//this.props.history('/pwd');
			//return false;
		}
		if(role === "operator"){
			this.props.history('/ticketentry');
			return false;
		}
		if(role === "officer"){
			this.props.history('/report');
			return false;
		}
		if(role === "gatekeeper"){
			this.props.history('/scan');
			return false;
		}
		if(role === "collection"){
			this.props.history('/collect');
			return false;
		}

	}
	render(){
		return (
			<></>
		)
	}
}
export default HomeComponent